import {config} from "../../Constants";
import getDateFormatter from "../../utils/getDateFormatter";

export default async function getHistoryExchange(user, code) {
    const response = await fetch(config.url.API_URL + '/api/v1/exchange-transactions?currency=' + code, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.token,
        },
    })

    const result = await response.json()

    const formatter = getDateFormatter()

    return result.map(transaction => {
        return {
            dateTime: formatter.format(new Date(transaction.date)),
            pair: transaction.currencyDebit + ' -> ' + transaction.currencyCredit,
            rate: transaction.rate,
            debit: transaction.amountDebit,
            credit: transaction.amountCredit,
        }
    })
}
