export default function numberFormat(number, decimals = 0, dec_point = '.', thousands_sep = ' ') {
    const sign = number < 0 ? '-' : ''

    const s_number = Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + ''

    const len = s_number.length
    const chunk = len > 3 ? len % 3 : 0

    const ch_first = (chunk ? s_number.substring(0, chunk) + thousands_sep : '')
    const ch_rest = s_number.substring(chunk).replace(/(\d\d\d)(?=\d)/g, '$1' + thousands_sep)
    const ch_last = decimals ? dec_point + (Math.abs(number) - s_number).toFixed(decimals).slice(2) : ''

    return sign + ch_first + ch_rest + ch_last
}