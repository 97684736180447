import copy from "../../../img/form/copy.svg"
import CryptoDepositHistory from "./CryptoDepositHistory"
import {useEffect, useState} from "react"
import newWalletNumber from "../../../api/deposit/newWalletNumber"
import getHistoryDepositCrypto from "../../../api/deposit/getHistoryDepositCrypto";
import getCommissionsDepositCrypto from "../../../api/deposit/getCommissionsDepositCrypto";
import getWalletNumbers from "../../../api/deposit/getWalletNumbers";
import QRCode from "react-qr-code";

export default function CryptoDeposit({user, account}) {
    const currency = account.currency
    const currencyCode = currency.code.toUpperCase()

    const networks = currency.networks

    let messages = {
        usdt: {
            ERC20: {
                format: 'Это адрес депозита в формате ERC20. 42 символа.',
                min: 'Минимальная сумма депозита: %min% USDT',
            },
            TRC20: {
                format: 'Это адрес депозита в формате TRC20 (сеть ТRON). 34 символа.',
                min: 'Минимальная сумма депозита: %min% USDT',
            },
            BEP20: {
                format: 'Это адрес депозита в формате BEP20 (Binance smart chain). 42 символа.',
                min: '',
            },
        },
        btc: {
            BTC: {
                format: 'Это адрес депозита в формате BTC. 34 символа.',
                min: 'Минимальная сумма депозита: %min% BTC',
            },
            BEP20: {
                format: 'Это адрес депозита в формате BEP20 (Binance smart chain). 42 символа.',
                min: '',
            },
        },
        eth: {
            ERC20: {
                format: 'Это адрес депозита в формате ERC20. 42 символа.',
                min: '',
            },
            BEP20: {
                format: 'Это адрес депозита в формате BEP20 (Binance smart chain). 42 символа.',
                min: '',
            },
        },
        dai: {
            ERC20: {
                format: 'Это адрес депозита в формате ERC20. 42 символа.',
                min: 'Минимальная сумма депозита: %min% DAI',
            },
            BEP20: {
                format: 'Это адрес депозита в формате BEP20 (Binance smart chain). 42 символа.',
                min: '',
            },
        },
    }

    let [network, setNetwork] = useState(networks[0])
    const [numbers, setNumbers] = useState({})

    const [commissions, setCommissions] = useState({})
    const [history, setHistory] = useState([])

    useEffect(() => {
        getWalletNumbers(user, currency.code).then(numbers => setNumbers(numbers))
        getCommissionsDepositCrypto(user, currency.code).then(commissions => setCommissions(commissions))
    }, [account])

    useEffect(() => {
        getHistoryDepositCrypto(user, currency.code).then(history => setHistory(history))
    }, [account])

    if (!(network in messages[currency.code])) {
        network = Object.keys(messages[currency.code])[0]
    }

    const commissionSettings = commissions[network] || {
        min: 0,
        max: 0,
        commission: 0,
        commissionMin: 0,
        commissionFixed: 0,
    }

    const message = (messages[currency.code][network] || {min: ''})

    message.min = message.min
        .replace('%min%', commissionSettings.min)
        .replace('%max%', commissionSettings.max)

    async function newAddress() {
        let newNumbers = {...numbers}

        newNumbers[network] = await newWalletNumber(user, currency.code, network)

        setNumbers(newNumbers)
    }

    return <div className="page_caontent_right">
        <h1 className="page_title">{`Ввод средств в ${currencyCode}`}</h1>

        <h2 className="page_sub_title">Выберите сеть</h2>

        <div className="set">
            {networks.map((name, i) => (
                <label key={i} className={network === name ? 'radi_btn_active' : ''} htmlFor={'radio-' + i} onClick={() => setNetwork(name)}>
                    <div className="radi_btn">
                        <div className="radio_btn_act"></div>
                    </div>
                    <input type="radio" value={name} id={'radio-' + i}/>{name}
                </label>
            ))}
        </div>

        <div className="qr_code">
            <div className="qr_cod_text">
                <p className="qr_cod_text_par">Скопируйте этот адрес в свой кошелек, введите желаемую сумму для зачисления и подтвердите операцию.</p>
                {message.format && <p className="qr_cod_text_red">{message.format}</p>}
                {message.min && <p className="qr_cod_text_red">{message.min}</p>}
            </div>
            <div style={{height: "auto", margin: "0 auto", maxWidth: 150, width: "100%"}}>
                {numbers[network] && <QRCode
                    size={256}
                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                    value={numbers[network]}
                    viewBox={`0 0 256 256`}
                />}
            </div>
        </div>

        <div className="adres">
            <h2 className="page_sub_title">Адрес</h2>

            <div className="adres_content">
                <div className="qr_text_copy">
                    {numbers[network]}
                    <div className="copy_icon" onClick={() => navigator.clipboard.writeText(numbers[network])}>
                    <img src={copy} alt=""/>
                    </div>
                </div>

                <button type="submit" onClick={newAddress}>Получить новый адрес</button>
            </div>
        </div>

        <CryptoDepositHistory history={history} currency={currency}/>
    </div>
}
