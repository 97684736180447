import {NavLink} from "react-router-dom";

export default function Forbidden() {
    return <main className="main_content">
        <div className="content">
            <div className="dostup_content">
                <h1 className="dost_title">Доступ запрещен</h1>
                <p className="dost_sub_title">У вас нет прав для просмотра этой страницы</p>
                <p className="link_to_home"><NavLink to="/" className="home_link">Перейти на главную</NavLink></p>
            </div>
        </div>
    </main>
}
