import getCommissions from "../getCommissions"

export default async function getCommissionsWithdrawCrypto(user, code) {
    const data = await getCommissions(user, code, 'withdraw')

    let result = {}

    data.forEach(commission => {
        result[commission.network] = {
            min: commission.amountMin,
            max: commission.amountMax,
            commission: commission.commission,
            commissionMin: commission.commissionMin,
            commissionFixed: commission.commissionFixed,
        }
    })

    return result
}
