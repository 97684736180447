import arrowLeft from "../img/arrow_left.svg"

export default function Pagination({all, current}) {
    return <div className="table_navigation">
        <div className="arrow_prew">
            <img src={arrowLeft} alt=""/>
        </div>

        <div className="pagination">
            {[...Array(all).keys()]
                .map(item => item + 1)
                .map(item => <a href="#" className={item === current ? 'pagin_item_active' : ''} key={item}>{item}</a>)}
        </div>

        <div className="arrow_next">
            <img src={arrowLeft} alt=""/>
        </div>
    </div>
}
