import {config} from '../../Constants'
import getDateFormatter from "../../utils/getDateFormatter";
import getTransactionStatuses from "../../utils/getTransactionStatuses";

export default async function depositFiat(user, currency, method, amount) {
    const response = await fetch(config.url.API_URL + '/api/v1/fiat-deposit-transactions', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'Bearer ' + user.token,
        },
        body: JSON.stringify({
            currency: currency.code,
            method: method,
            amount: amount,
        }),
    })

    const transaction = await response.json()

    return {
        dateTime: getDateFormatter().format(new Date(transaction.date)),
        method: {cash: 'Наличные в офисе'}[method] || '',
        amount: (+transaction.amount).toFixed(currency.decimal),
        commission: (+transaction.commission).toFixed(currency.decimal),
        status: getTransactionStatuses()[transaction.status],
    }
}
