import getCommissions from "../getCommissions"
import getDepositMethods from "../../utils/getDepositMethods";
export default async function getCommissionsDepositFiat(user, code) {
    const data = await getCommissions(user, code, 'deposit')

    let result = {}

    data.forEach(commission => {
        const methodDeposit = commission.methodDeposit.toLowerCase()

        result[methodDeposit] = {
            min: commission.amountMin,
            max: commission.amountMax,
            commission: commission.commission,
            commissionMin: commission.commissionMin,
            commissionFixed: commission.commissionFixed,
            name: getDepositMethods()[methodDeposit]
        }
    })

    return result
}
