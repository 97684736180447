import {config} from '../../Constants'

export default async function getAuthToken(email, password) {
    return await fetch(config.url.API_URL + '/api/v1/auth-tokens', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(
            {email: email, password: password}
        ),
    })
}
