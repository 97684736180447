import {config} from "../../Constants"

export default async function newWalletNumber(user, code, network) {
    const response = await fetch(config.url.API_URL + '/api/v1/wallet-numbers', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'Bearer ' + user.token,
        },
        body: JSON.stringify({
            currency: code,
            network: network,
        }),
    })

    const walletNumber = await response.json()

    return walletNumber.number
}
