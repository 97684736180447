import Pagination from "../../Pagination"
import {Link} from "react-router-dom"

export default function FiatWithdrawHistory({history}) {
    const offset = 0
    const itemOnPage = 10
    const countPages = Math.ceil(history.length / itemOnPage)
    const currentPage = Math.floor(offset / itemOnPage) + 1

    return <div className="operations_history">
        <h2 className="page_title">История операций</h2>

        <table className="history_table">
            <thead>
            <tr>
                <th><Link to="?datetime">Время</Link></th>
                <th><Link to="?number">Счёт вывода</Link></th>
                <th><Link to="?amount">Сумма</Link></th>
                <th className="activ_sort"><Link to="?commission">Комиссия</Link></th>
                <th><Link to="?status">Статус</Link></th>
            </tr>
            </thead>
            <tbody>
            {history.slice(offset, offset + itemOnPage).map(({dateTime, number, amount, commission, status}, i) => (
                <tr key={i}>
                    <td>{dateTime}</td>
                    <td>{number}</td>
                    <td>{(+amount).toFixed(2)}</td>
                    <td>{(+commission).toFixed(2)}</td>
                    <td>{status}</td>
                </tr>
            ))}
            </tbody>
        </table>

        {countPages > 1 && <Pagination all={countPages} current={currentPage}/>}
    </div>
}
