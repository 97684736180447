import getCommissions from "../getCommissions"

export default async function getCommissionsWithdrawFiat(user, code) {
    const data = await getCommissions(user, code, 'withdraw')

    const prefixes =  {
        cash: {
            prefix: ''
        },
        transfer_sber: {
            prefix: 'Перевод на карту:'
        },
        transfer_tinkoff: {
            prefix: 'Перевод на карту:'
        },
        transfer_all: {
            prefix: 'Перевод на карту:'
        },
        atm_sber: {
            prefix: 'Пополнение карты:'
        },
        atm_tinkoff: {
            prefix: 'Пополнение карты:'
        },
        atm_all: {
            prefix: 'Пополнение карты:'
        },
    }

    let result = {}

    data.forEach(commission => {
        const methodWithdraw = commission.methodWithdraw.toLowerCase()

        result[methodWithdraw] = {
            min: commission.amountMin,
            max: commission.amountMax,
            commission: commission.commission,
            commissionMin: commission.commissionMin,
            commissionFixed: commission.commissionFixed,
            prefix: prefixes[methodWithdraw].prefix
        }
    })

    return result
}
