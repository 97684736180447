import {useParams} from "react-router-dom"
import Exchange from "./Exchange"

export default function ExchangeWrapper({user, accounts, updateAccounts}) {
    const {code} = useParams()

    const account = accounts.find(item => item.currency.code === code)
    const otherAccounts = accounts.filter(item => item.currency.code !== account.currency.code)

    return <Exchange user={user} account={account} otherAccounts={otherAccounts} updateAccounts={updateAccounts}/>
}
