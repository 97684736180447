import tabOpen from "../../img/tabl_open.svg"
import styles from './Main.module.css'
import {useEffect, useState} from "react";
import getExchangeRates from "../../api/exchange/getExchangeRates";
import numberFormat from "../../utils/numberFormat";

export default function Main() {
    const [rates, setRates] = useState([])

    useEffect(() => {
        getExchangeRates()
            .then(function (result) {
                const rates = Object.keys(result['rub']).map(function (code) {
                    return {
                        code: code.toUpperCase(),
                        rate: numberFormat(1 / result['rub'][code].rate, 2),
                    }
                })

                setRates(rates)
            })
    }, []);

    return <main className={styles.main_content}>
        <div className="content">
            <div className="home_content">
                <h1 className="home_title">Понятный криптообменник</h1>
                <p className="home_sub_title">Рубль, Bitcoin, USDT, USDC, ETH, DAI</p>
                <p className="home_sub_title">Пополнение и вывод любых средств с минимальной комиссией круглосуточно</p>

                <div className="curs_exchange">
                    <div className="exchange_tables">
                        <table>
                            <thead>
                            <tr>
                                <th colSpan="2">Курс продажи (RUB) <img src={tabOpen} alt=""/></th>
                            </tr>
                            </thead>
                        </table>
                        <table className="table_open">
                            <thead>
                            <tr>
                                <th colSpan="2">Курс покупки (RUB) <img src={tabOpen} alt=""/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {rates.map(({code, rate}, i) => (
                                <tr key={i}>
                                    <td>{code}:</td>
                                    <td>{rate}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
}
