import FiatDepositHistory from "./FiatDepositHistory"
import {useEffect, useRef, useState} from "react"
import getHistoryDepositFiat from "../../../api/deposit/getHistoryDepositFiat"
import getCommissionsDepositFiat from "../../../api/deposit/getCommissionsDepositFiat";
import paintBorder from "../../../utils/paintBorder";
import calcCommission from "../../../utils/calcCommission";
import depositFiat from "../../../api/deposit/depositFiat";

export default function FiatDeposit({user, account, updateAccounts}) {
    const currency = account.currency
    const currencyCode = currency.code.toUpperCase()

    const messages = {
        cash: 'Москва, Новинский бульвар 12. Нажмите "Подтвердить" для создания заявки. Созданные заявки действительны до 00:00 текущего дня. Просим Вас не приходить в офис без предварительной договоренности.',
        // card: 'Переведи деньги на карту 1234 5678 1234 5678',
        // phone: 'Переведи деньги на телефон +7 920 1234567',
    }

    const [amount, setAmount] = useState('')
    const [method, setMethod] = useState('')

    const amountInputRef = useRef()
    const methodInputRef = useRef()

    function changeAmount(event) {
        setAmount(event.target.value)

        paintBorder(amountInputRef, false)
    }

    function changeMethod(event) {
        setMethod(event.target.value)

        paintBorder(methodInputRef, false)
    }

    const [commissions, setCommissions] = useState({})
    const [history, setHistory] = useState([])

    useEffect(() => {
        getCommissionsDepositFiat(user, currency.code).then(commissions => setCommissions(commissions))
    }, [])

    useEffect(() => {
        getHistoryDepositFiat(user, currency.code).then(history => setHistory(history))
    }, [commissions])

    const commissionSettings = commissions[method] || {
        min: 0,
        max: 0,
        commission: 0,
        commissionMin: 0,
        commissionFixed: 0,
        name: '',
    }

    const amountCommission = calcCommission(amount, commissionSettings)
    const amountDeposit = (parseFloat(amount || 0) + amountCommission).toFixed(currency.decimal)

    const message = messages[method] || ''

    const conditionsBlock = message && <div className="conditions">
        <h2 className="page_sub_title">Условия</h2>
        <p className="conditions_text">{message}</p>
    </div>

    async function execute() {
        if (method === '') {
            paintBorder(methodInputRef, true)

            return
        }

        if (amount < commissionSettings.min) {
            paintBorder(amountInputRef, true)

            return
        }

        const newItem = await depositFiat(user, currency, method, amount)

        setHistory([newItem, ...history])

        setAmount('')

        updateAccounts()
    }

    return <div className="page_caontent_right">
        <h1 className="page_title">{`Ввод средств в ${currencyCode}`}</h1>

        <div className="replenishment">
            <div className="replenishment_item">
                <h2 className="page_sub_title">Способ пополнения</h2>

                <div className="select_drop">
                    <select value={method} ref={methodInputRef} onChange={changeMethod}>
                        <option value="">Выберите способ пополнения</option>
                        {Object.keys(commissions).map((method) => (
                            <option value={method}>{commissions[method].name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="replenishment_item">
                <h2 className="page_sub_title">Сумма депозита</h2>
                <input
                    type="text"
                    value={amount}
                    ref={amountInputRef}
                    placeholder={amount ? `Минимум ${commissionSettings.min}` : 'Введите сумму депозита'}
                    onChange={changeAmount}
                />
            </div>
        </div>

        {conditionsBlock}

        <div className="payment_info">
            <form>
                <div className="payment_info_item payment_info_item_commission">
                    <h2 className="page_sub_title">Комиссия</h2>
                    <input type="text" value={amountCommission} readOnly/>
                </div>

                <div className="payment_info_item payment_info_item_sale">
                    <h2 className="page_sub_title">Сумма к оплате</h2>
                    <input type="text" value={amountDeposit} readOnly/>
                </div>

                <div className="payment_info_item">
                    <button type="button" className="payment_submit" onClick={execute}>Далее</button>
                </div>
            </form>
        </div>

        <FiatDepositHistory history={history}/>
    </div>
}
