import CryptoWithdrawHistory from "./CryptoWithdrawHistory"
import {useEffect, useRef, useState} from "react"
import getHistoryWithdrawCrypto from "../../../api/withdraw/getHistoryWithdrawCrypto"
import withdrawCrypto from "../../../api/withdraw/withdrawCrypto";
import paintBorder from "../../../utils/paintBorder";
import calcCommission from "../../../utils/calcCommission";
import getCommissionsWithdrawCrypto from "../../../api/withdraw/getCommissionsWithdrawCrypto";

export default function CryptoWithdraw({user, account, updateAccounts}) {
    const currency = account.currency
    const networks = currency.networks

    const [amount, setAmount] = useState('')
    const [number, setNumber] = useState('')

    const [commissions, setCommissions] = useState({})
    const [history, setHistory] = useState([])

    useEffect(() => {
        getCommissionsWithdrawCrypto(user, currency.code).then(commissions => setCommissions(commissions))
    }, [account])

    useEffect(() => {
        getHistoryWithdrawCrypto(user, currency.code).then(history => setHistory(history))
    }, [commissions])

    let [network, setNetwork] = useState(networks[0])

    // Потому что состояние общее для всех криптовалют и может хранить сеть от другой криптовалюты
    if (!networks.includes(network)) {
        network = networks[0]
    }

    const commissionSettings = commissions[network] || {
        min: 0,
        max: 0,
        commission: 0,
        commissionMin: 0,
        commissionFixed: 0,
    }

    const min = commissionSettings.min
    const amountCommission = calcCommission(amount, commissionSettings)
    const amountWithdraw = (amount && amount > min) ? (amount - amountCommission).toFixed(currency.decimal) : ''

    const amountInputRef = useRef()
    const numberInputRef = useRef()

    function changeNumber(event) {
        setNumber(event.target.value)

        paintBorder(numberInputRef, false)
    }

    function changeAmount(event) {
        setAmount(event.target.value)

        paintBorder(amountInputRef, false)
    }

    function withdrawAll() {
        setAmount(account.balance)

        paintBorder(amountInputRef, false)
    }

    async function execute() {
        if (number === '') {
            paintBorder(numberInputRef, true)

            return
        }

        if (amount < commissionSettings.min || amount > account.balance) {
            paintBorder(amountInputRef, true)

            return
        }

        const newItem = await withdrawCrypto(user, currency, network, number, amount)

        setHistory([newItem, ...history])

        setAmount('')
        setNumber('')

        updateAccounts()
    }

    return <div className="page_caontent_right">
        <h1 className="page_title">{`Вывод средств в ${currency.code.toUpperCase()}`}</h1>

        <p className="con_ustd_text">Заявка будет подтверждена в течение 30 минут.</p>

        <h2 className="page_sub_title">Выберите сеть</h2>

        <div className="set">
            {networks.map((name, i) => (
                <label
                    className={network === name ? 'radi_btn_active' : ''}
                    onClick={() => {
                        console.log(name)
                        setNetwork(name)
                    }}
                    htmlFor={'radio-' + i}
                    key={i}>
                    <div className="radi_btn">
                        <div className="radio_btn_act"></div>
                    </div>
                    <input type="radio" value={name} id={'radio-' + i}/>{name}
                </label>
            ))}
        </div>

        <div className="withdraw_info">
            <div className="dost_bal">
                <h2 className="page_sub_title">Доступно</h2>
                <input type="text" value={account.balance} readOnly/>
            </div>

            <div className="adr_vvoda">
                <h2 className="page_sub_title">Адрес вывода</h2>
                <input type="text" value={number} ref={numberInputRef} onChange={changeNumber} placeholder="Адрес получателя"/>
            </div>
        </div>

        <div className="sum_redirect">
            <div className="sum_vv">
                <h2 className="page_sub_title">Сумма вывода</h2>
                <input
                    type="text"
                    value={amount}
                    ref={amountInputRef}
                    placeholder={`Минимум ${min.toFixed(currency.decimal)}`}
                    onChange={changeAmount}
                />
            </div>

            <button type="button" className="con" onClick={withdrawAll}>Вывести все</button>

            <div className="comision_con">
                <h2 className="page_sub_title">Комиссия</h2>
                <input type="text" value={amountCommission} readOnly/>
            </div>

            <div className="sum_vpl">
                <h2 className="page_sub_title">Сумма выплаты</h2>
                <input type="text" value={amountWithdraw} readOnly/>
            </div>

            <button type="button" className="next_step" onClick={execute}>Далее</button>
        </div>

        <CryptoWithdrawHistory history={history} />
    </div>
}
