import {config} from "../../Constants";

export default async function getExchangeRates() {
    const response = await fetch(config.url.API_URL + '/api/v1/exchange-rates', {
        method: 'GET',
    })

    const data = await response.json()

    const result = {}

    for (let key in data) {
        const item = data[key]

        if (result[item.currencyFrom.toLowerCase()] === undefined) {
            result[item.currencyFrom.toLowerCase()] = {}
        }

        result[item.currencyFrom.toLowerCase()][item.currencyTo.toLowerCase()] = {
            commission: item.commission,
            rate: item.rate,
        }
    }

    return result
}

