import {config} from '../../Constants'

export default async function logout(user) {
    return await fetch(config.url.API_URL + '/api/v1/auth-tokens/' + user.token, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + user.token,
        },
    })
}
