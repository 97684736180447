import {config} from "../../Constants";
import getDateFormatter from "../../utils/getDateFormatter";
import getTransactionStatuses from "../../utils/getTransactionStatuses";

export default async function getHistoryDepositFiat(user, code) {
    const response = await fetch(config.url.API_URL + '/api/v1/fiat-deposit-transactions?currency=' + code, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.token,
        },
    })

    const result = await response.json()

    const formatter = getDateFormatter()

    return result.map(transaction => {
        return {
            dateTime: formatter.format(new Date(transaction.date)),
            method: transaction.method,
            amount: transaction.amount,
            commission: transaction.commission,
            status: getTransactionStatuses()[transaction.status],
        }
    })
}
