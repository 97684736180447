import {NavLink} from "react-router-dom"

export default function CurrencyCard({active, currency, balance, worth, icon}) {
    return <div className={active ? 'sitebar_item sitebar_item_active' : 'sitebar_item'}>
        <div className="sitebar_item_content">
            <div className="valut_name">
                <img src={'/img/currency/' + currency.code + '.svg'} alt=""/>
                <span>{currency.code}</span>
            </div>
            <div className="valute_info">
                <div className="main_bal">{balance.toFixed(currency.decimal)}</div>
                <div className="exch_balance">
                    <span className="exch_balance_name">₽</span>
                    <span className="exch_balance_sum">{worth}</span>
                </div>
            </div>
        </div>

        <div className="sitebar_item_nav">
            <NavLink to={`/accounts/${currency.code}/deposit`}>Ввод</NavLink>
            <NavLink to={`/accounts/${currency.code}/withdraw`}>Вывод</NavLink>
            <NavLink to={`/accounts/${currency.code}/exchange`}>Обмен</NavLink>
        </div>
    </div>
}
