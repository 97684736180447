import {useEffect, useRef, useState} from "react"
import ExchangeHistory from "./ExchangeHistory"
import getExchangeRates from "../../api/exchange/getExchangeRates"
import getHistoryExchange from "../../api/exchange/getHistoryExchange"
import st from "../../img/st.svg"
import pic from "../../img/pic.png"
import exchange from "../../api/exchange/exchange";
import paintBorder from "../../utils/paintBorder";
import numberFormat from "../../utils/numberFormat";

export default function Exchange({user, account, otherAccounts, updateAccounts}) {
    const currency = account.currency
    const currencyCode = currency.code.toUpperCase()

    const [amountDebit, setAmountDebit] = useState('')
    const [amountCredit, setAmountCredit] = useState(+amountDebit)

    let [currencyCodeCredit, setCurrencyCodeCredit] = useState(otherAccounts[2].currency.code)

    if (currency.code === currencyCodeCredit) {
        currencyCodeCredit = otherAccounts[0].currency.code
    }

    const accountCredit = otherAccounts.find(item => item.currency.code === currencyCodeCredit)
    const currencyCredit = accountCredit.currency

    const [history, setHistory] = useState([])
    const [rates, setRates] = useState(null)

    useEffect(() => {
        getExchangeRates().then(rates => setRates(rates))
        getHistoryExchange(user, currency.code).then(history => setHistory(history))
    }, [account])

    const amountInputRef = useRef()

    const rate = rates ? rates[currency.code][currencyCodeCredit].rate : 1
    const commission = rates ? rates[currency.code][currencyCodeCredit].commission : 0
    const amountCommission = amountDebit * commission

    function changeAmount(event) {
        setAmountDebit(event.target.value)
        setAmountCredit((event.target.value - amountCommission) * rate)

        paintBorder(amountInputRef, false)
    }

    function changeCurrency(event) {
        setCurrencyCodeCredit(event.target.value)

        setAmountCredit((amountDebit - (amountDebit * commission)) * rates[currency.code][event.target.value].rate)

        paintBorder(amountInputRef, false)
    }

    async function execute() {
        if (amountDebit <= 0 || amountDebit > account.balance) {
            paintBorder(amountInputRef, true)

            return
        }

        await exchange(user, currencyCode, currencyCodeCredit.toUpperCase(), amountDebit)

        setAmountDebit('')
        setAmountCredit(0)

        updateAccounts()
    }

    return <div className="page_caontent_right">
        <h1 className="page_title">Обмен средств</h1>

        <div className="exchange">
            <div className="exchange_left_blok">
                <div className="exchange_select">
                    <input
                        type="text"
                        value={amountDebit}
                        placeholder={currencyCode}
                        onChange={changeAmount}
                        ref={amountInputRef}
                    />

                    <img src={st} alt=""/>

                    <div className="select_drop">
                        <select value={currencyCodeCredit} onChange={changeCurrency}>
                            {otherAccounts.map((item, i) => (
                                <option key={i} value={item.currency.code}>{item.currency.code.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="course">
                    <h2 className="page_sub_title">Курс</h2>
                    <div className="exchange_setings">
                        {/*<input type="text" value={`${numberFormat(rate / 100, 8)}%`} className="curse_inp" readOnly/>*/}

                        <label className="manu_label">
                            <input type="text" value={numberFormat(rate, currencyCredit.decimal)} readOnly/>
                            <span className="exc_manu_name">{currencyCodeCredit.toUpperCase()}</span>
                        </label>
                    </div>

                    <h2 className="page_sub_title">Комиссия</h2>
                    <div className="exchange_setings">
                        <input type="text" value={`${numberFormat(commission * 100, 4)}%`} className="curse_inp" readOnly/>

                        <label className="manu_label">
                            <input type="text" value={amountCommission} readOnly/>
                            <span className="exc_manu_name">{currencyCode}</span>
                        </label>
                    </div>

                    <h2 className="page_sub_title">Всего</h2>
                    <div className="exchange_setings exchange_setings_buton">
                        <label className="manu_label manu_label_end">
                            <input type="text" value={numberFormat(amountCredit, currencyCredit.decimal)} readOnly/>
                            <span className="exc_manu_name">{currencyCodeCredit.toUpperCase()}</span>
                        </label>

                        <button type="button" onClick={execute}>Обменять</button>
                    </div>
                </div>
            </div>

            <div className="exchange_diagra">
                <img src={pic} alt=""/>
            </div>
        </div>

        <ExchangeHistory history={history} currencyDebit={currency}/>
    </div>
}
