import {NavLink, useNavigate} from "react-router-dom"
import {useRef, useState} from "react"
import SocialAuth from "../components/SocialAuth";
import hide from "../img/form/hide.svg"
import view from "../img/form/view.svg"
import getAuthTokenResp from "../api/auth/getAuthToken";
import {config} from '../Constants'
import paintBorder from "../utils/paintBorder";

export default function Registration({user, setUser}) {
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const emailInputRef = useRef()
    const password1InputRef = useRef()
    const password2InputRef = useRef()

    const navigate = useNavigate()

    function showInputValue(event, inputRef) {
        const passHidden = inputRef.current.getAttribute('type') === 'password'

        inputRef.current.setAttribute('type', passHidden ? 'text' : 'password')
        event.target.setAttribute('src', passHidden ? hide : view)
    }

    async function execute() {
        if (email && password1 && password2 && password1 === password2) {
            let responseUser = await fetch(config.url.API_URL + '/api/v1/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(
                    {
                        username: '',
                        email: email,
                        password: password1,
                        role: 'USER',
                    },
                ),
            })

            if (responseUser.status === 201) {
                let responseAuth = await getAuthTokenResp(email, password1)

                if (responseAuth.status === 201) {
                    const result = await responseAuth.json()

                    const user = {
                        id: result['userId'],
                        token: result['token'],
                    }

                    setUser(user)

                    navigate('/accounts/usdt/deposit')
                }
            }
        }

        paintBorder(emailInputRef, !email)
        paintBorder(password1InputRef, !password1)
        paintBorder(password2InputRef, !password2)
    }

    return <main className="main_content registration">
        <div className="content">
            <h1 className="page_title">Создать учетную запись</h1>

            <SocialAuth/>

            <div className="text_reg_form">
                <p>Зарегистрируйтесь с помощью электронной почты</p>
                <p>У вас уже есть учетная запись? <NavLink to="/login">Войти</NavLink></p>
            </div>

            <form className="form_reg">
                <label>
                    <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        ref={emailInputRef}
                        placeholder="Адрес электронной почты"
                    />
                </label>
                <label>
                    <input
                        type="password"
                        value={password1}
                        onChange={event => setPassword1(event.target.value)}
                        ref={password1InputRef}
                        placeholder="Пароль"
                    />
                    <img
                        src={view} alt="" className="hide_pass"
                        onClick={event => showInputValue(event, password1InputRef)}
                    />
                </label>
                <label>
                    <input
                        type="password"
                        value={password2}
                        onChange={event => setPassword2(event.target.value)}
                        ref={password2InputRef}
                        placeholder="Повторите пароль"
                    />
                    <img
                        src={view} alt="" className="hide_pass"
                        onClick={event => showInputValue(event, password2InputRef)}
                    />
                </label>
                <label>
                    <button type="button" onClick={execute}>Регистрация</button>
                </label>
            </form>
        </div>
    </main>
}
