import logo from "../img/logo.svg"
import whatsapp from "../img/socials/whatsapp.svg"
import facebook from "../img/socials/facebook.svg"
import instagram from "../img/socials/Instagram.svg"
import youTube from "../img/socials/YouTube.svg"

export default function Footer() {
    return <footer>
        <div className="footer_top">
            <div className="content">
                <div className="footer_navigation">
                    <div className="logo">
                        <a href="/"><img src={logo} alt="GITSEXCHANGE"/></a>
                    </div>
                    <div className="pol_conf">
                        <a href="#">Правила и комиссии</a>
                        <a href="#">Политика конфиденциальности</a>
                    </div>
                    <div className="pol_conf">
                        <a href="#">Пользовательское соглашение</a>
                        <a href="#">Политика использования Cookie</a>
                    </div>
                    <div className="soc_icons">
                        <a href="#"><img src={whatsapp} alt="Whatsapp" /></a>
                        <a href="#"><img src={facebook} alt="Facebook" /></a>
                        <a href="#"><img src={instagram} alt="Instagram" /></a>
                        <a href="#"><img src={youTube} alt="YouTube" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer_bottom">
            <div className="content">Copyright © 2023-2024 gits-exchange</div>
        </div>
    </footer>
}
