import CryptoWithdraw from "./CryptoWithdraw/CryptoWithdraw"
import FiatWithdraw from "./FiatWithdraw/FiatWithdraw"
import {useParams} from "react-router-dom"

export default function Withdraw({user, accounts, updateAccounts}) {
    const {code} = useParams()

    const account = accounts.find(item => item.currency.code === code)

    return account.currency.type === 'fiat'
        ? <FiatWithdraw user={user} account={account} updateAccounts={updateAccounts}/>
        : <CryptoWithdraw user={user} account={account} updateAccounts={updateAccounts}/>
}
