import gmail from "../img/oauth/gmail.svg"
import fb from "../img/oauth/fb.svg"
import vk from "../img/oauth/vk.svg"
import apple from "../img/oauth/apple.svg"

export default function SocialAuth() {
    const socials = [
        {
            src: gmail,
            alt: 'gmail',
        },
        {
            src: fb,
            alt: 'fb',
        },
        {
            src: vk,
            alt: 'vk',
        },
        {
            src: apple,
            alt: 'apple',
        },
    ]

    return socials.length > 0 && <>
        <div className="registr_soc">
            {socials.map((item, i) => (
                <div className="registr_soc_item" key={i}>
                    <a href="#"><img src={item.src} alt={item.alt}/></a>
                </div>
            ))}
        </div>

        <div className="linear_blc">
            <div className="linear"></div>
            <div className="linear_text">Или</div>
            <div className="linear"></div>
        </div>
    </>
}
