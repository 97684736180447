import Pagination from "../Pagination"
import {Link} from "react-router-dom"
import numberFormat from "../../utils/numberFormat";

export default function ExchangeHistory({history, currencyDebit}) {
    const offset = 0
    const itemOnPage = 10
    const countPages = Math.ceil(history.length / itemOnPage)
    const currentPage = Math.floor(offset / itemOnPage) + 1

    return <div className="operations_history operations_history_exch">
        <h2 className="page_title">История операций</h2>

        <table className="history_table">
            <thead>
            <tr>
                <th><Link to="?datetime">Время</Link></th>
                <th><Link to="?pair">Пара</Link></th>
                <th className="activ_sort"><Link to="?rate">Курс</Link></th>
                <th><Link to="?debit">Отправил</Link></th>
                <th><Link to="?credit">Получил</Link></th>
            </tr>
            </thead>
            <tbody>
            {history.slice(offset, offset + itemOnPage).map(({dateTime, pair, rate, debit, credit}, i) => {
                return <tr key={i}>
                    <td>{dateTime}</td>
                    <td>{pair}</td>
                    <td>{numberFormat(rate, 8)}</td>
                    <td>{numberFormat(debit, currencyDebit.decimal)}</td>
                    <td>{credit}</td>
                </tr>
            })}
            </tbody>
        </table>

        {countPages > 1 && <Pagination all={countPages} current={currentPage}/>}
    </div>
}
