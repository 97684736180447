import {Routes, Route, Navigate} from "react-router-dom"
import Footer from "./components/Footer"
import Accounts from "./pages/Accounts"
import Header from "./components/Header"
import Login from "./pages/Login"
import Registration from "./pages/Registration"
import ScrollToTop from "./utils/ScrollToTop"
import Trading from "./pages/Trading"
import Profile from "./pages/Profile"
import Logout from "./pages/Logout"
import Main from "./pages/Main/Main"
import PageNotFound from "./pages/PageNotFound"
import Forbidden from "./pages/Forbidden"
import {useLocalStorage} from "./utils/useLocalSorage"

export default function App() {
    const [user, setUser] = useLocalStorage('user', null);

    const redirectToLogin = <Navigate to="/login" replace />
    const redirectToAccounts = <Navigate to="/accounts/usdt/deposit" replace />

    return (
        <>
            <ScrollToTop/>

            <Header user={user}/>

            <Routes>
                <Route path="/profile" element={user ? <Profile/> : redirectToLogin}/>
                <Route path="/trading" element={user ? <Trading/> : redirectToLogin}/>
                <Route path="/accounts/*" element={user ? <Accounts user={user}/> : redirectToLogin}/>
                <Route path="/logout" element={user ? <Logout user={user} setUser={setUser}/> : redirectToLogin}/>
                <Route path="/registration" element={user ? redirectToAccounts : <Registration user={user} setUser={setUser}/>}/>
                <Route path="/login" element={user ? redirectToAccounts :  <Login user={user} setUser={setUser}/>}/>
                <Route path="/main" element={<Main/>}/>
                <Route path="/" element={<Main/>}/>
                <Route path="*" element={<PageNotFound/>}/>
                <Route path="/403" element={<Forbidden/>}/>
            </Routes>

            <Footer/>
        </>
    )
}
