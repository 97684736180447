import {config} from '../Constants'

export default async function getAccounts(user) {
    const response = await fetch(config.url.API_URL + '/api/v1/accounts', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.token,
        },
    })

    if (response.status !== 200) {
        throw new Error("Error")
    }

    return await response.json()
}
