import {useState, useEffect} from 'react'

function getStorageValue(key, defaultValue) {
    const value = localStorage.getItem(key)

    return value ? JSON.parse(value) : defaultValue
}

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}
