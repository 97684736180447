import {Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import CurrencyBlock from "../components/CurrencyBlock";
import Deposit from "../components/Deposit/Deposit";
import ExchangeWrapper from "../components/Exchange/ExchangeWrapper";
import Withdraw from "../components/Withdraw/Withdraw";
import getAccounts from "../api/getAccounts";
import Forbidden from "./Forbidden";

export default function Accounts({user}) {
    const [accounts, setAccounts] = useState([])
    const [allWorth, setAllWorth] = useState(0)

    const [forbidden, setForbidden] = useState(false)

    function calcWorth(accounts) {
        return accounts.reduce((accumulator, {worth}) => parseFloat(accumulator) + parseFloat(worth), 0)
    }

    function updateAccounts() {
        getAccounts(user)
            .then(newAccounts => {
                setAccounts(newAccounts)
                setAllWorth(calcWorth(newAccounts))
            })
            .catch((e) => setForbidden(true))
    }

    useEffect(() => {
        updateAccounts()
    }, [user])

    if (forbidden) {
        return <Forbidden/>
    }

    // Если данные еще не загрузились
    if (accounts.length === 0) {
        return <></>
    }

    return <main className="main_content">
        <div className="content">
            <p className="balance_info">{`Общая стоимость активов: ${allWorth.toFixed(2)} ₽`}</p>

            <section className="page_content">
                <div className="contetn_wrap">
                    <CurrencyBlock accounts={accounts} />

                    <Routes>
                        <Route path=":code/deposit" element={<Deposit user={user} accounts={accounts} updateAccounts={updateAccounts}/>}/>
                        <Route path=":code/exchange" element={<ExchangeWrapper user={user} accounts={accounts} updateAccounts={updateAccounts}/>}/>
                        <Route path=":code/withdraw" element={<Withdraw user={user} accounts={accounts} updateAccounts={updateAccounts}/>}/>
                    </Routes>
                </div>
            </section>
        </div>
    </main>
}
