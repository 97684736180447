export default function luhn(pan) {
    let nCheck = 0;
    console.log(pan)
    if (pan && /[0-9-\s]+/.test(pan)) {
        pan = pan.replace(/\D/g, '')

        pan.split('').forEach((v, n) => {
            let nDigit = parseInt(v, 10)

            if (!((pan.length + n) % 2) && (nDigit *= 2) > 9) {
                nDigit -= 9
            }

            nCheck += nDigit
        })
    }

    return (nCheck % 10) === 0;
};