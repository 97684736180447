import {config} from "../../Constants";

export default async function getWalletNumbers(user, code) {
    const response = await fetch(config.url.API_URL + '/api/v1/wallet-numbers?currency=' + code, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.token,
        },
    })

    const data = await response.json()

    const result = {}

    data.forEach(item => {
        if (result[item.network] === undefined) {
            result[item.network] = item.number
        }
    })

    return result
}
