import FiatWithdrawHistory from "./FiatWithdrawHistory"
import {useEffect, useRef, useState} from "react"
import getHistoryWithdrawFiat from "../../../api/withdraw/getHistoryWithdrawFiat"
import withdrawFiat from "../../../api/withdraw/withdrawFiat";
import getCommissionsWithdrawFiat from "../../../api/withdraw/getCommissionsWithdrawFiat";
import numberFormat from "../../../utils/numberFormat";
import paintBorder from "../../../utils/paintBorder";
import luhn from "../../../utils/luhn";
import calcCommission from "../../../utils/calcCommission";

export default function FiatWithdraw({user, account, updateAccounts}) {
    const currency = account.currency
    const currencyCode = currency.code.toUpperCase()

    const messages = {
        rub: {
            cash: 'График работы: круглосуточно. Минимальная сумма вывода — от %min% ₽, отображается в поле «Сумма выплаты». Созданные заявки действительны до 00:00 текущего дня. ВНИМАНИЕ! Просим Вас не приходить в офис без предварительной договоренности.',
            transfer_sber: 'Комиссия %commission%%. Минимальная сумма вывода – %min% ₽. Выплаты производятся в течение 60 минут. Для вывода необходимо указать номер карты.',
            transfer_tinkoff: 'Комиссия %commission%%. Минимальная сумма вывода – %min% ₽. Выплаты производятся в течение 60 минут. Заявки на вывод Тинькофф (с карты на карту) осуществляются по номеру карты',
            transfer_all: 'Выплата на карту любого российского банка, комиссия %commission%%. VISA, Mastercard, МИР. Минимум %min% ₽, максимум %max% ₽ за один платёж. Выплаты производятся с 10:00 до 20:00 (GMT +3)',
            atm_sber: 'Комиссия %commission%%. Пополнение вашей карты Сбербанк наличными. Минимальная сумма – %min% ₽. Заявки осуществляются по номеру карты или договора. Выплаты производятся в течение 60 минут.',
            atm_tinkoff: 'Комиссия %commission%%. Пополнение вашей карты Тинькофф наличными. Минимальная сумма – %min% ₽. Заявки осуществляются по номеру карты или договора. Выплаты производятся в течение 60 минут.',
            atm_all: 'Выплата на карту любого российского банка, комиссия %commission%%. VISA, Mastercard, МИР. Минимум %min% ₽, максимум %max% ₽ за один платёж. Выплаты производятся с 10:00 до 20:00 (GMT +3).',
        },
    }

    const [method, setMethod] = useState('cash')
    const [amount, setAmount] = useState('')
    const [pan, setPan] = useState('')

    const [commissions, setCommissions] = useState({})
    const [history, setHistory] = useState([])

    useEffect(() => {
        getCommissionsWithdrawFiat(user, currency.code).then(commissions => setCommissions(commissions))
    }, [account])

    useEffect(() => {
        getHistoryWithdrawFiat(user, currency.code, commissionSettings.prefix).then(history => setHistory(history))
    }, [commissions])

    const commissionSettings = commissions[method] || {
        min: 0,
        max: 0,
        commission: 0,
        commissionMin: 0,
        commissionFixed: 0,
        prefix: '',
    }

    const amountCommission = calcCommission(amount, commissionSettings)
    const amountWithdraw = parseFloat(amount || 0) + amountCommission

    const methodInputRef = useRef()
    const amountInputRef = useRef()
    const panInputRef = useRef()

    const message = (messages[currency.code][method] || '')
        .replace('%commission%', (commissionSettings.commission * 100).toFixed())
        .replace('%min%', numberFormat(commissionSettings.min))
        .replace('%max%', numberFormat(commissionSettings.max))

    function changeAmount(event) {
        setAmount(event.target.value)

        paintBorder(amountInputRef, false)
    }

    function changePan(event) {
        setPan(event.target.value)

        paintBorder(panInputRef, false)
    }

    async function execute() {
        if (method === '') {
            paintBorder(methodInputRef, true)

            return
        }

        if (method !== 'cash' && (pan.length < 16 || pan.length > 19 || !luhn(pan))) {
            paintBorder(panInputRef, true)

            return
        }

        if (amount < commissionSettings.min || amount > account.balance) {
            paintBorder(amountInputRef, true)

            return
        }

        const newItem = await withdrawFiat(user, currency, method, pan, amount, commissionSettings.prefix)

        setHistory([newItem, ...history])

        setAmount('')
        setPan('')

        updateAccounts()
    }

    return <div className="page_caontent_right">
        <h1 className="page_title">{`Вывод средств в ${currencyCode}`}</h1>

        <div className="replenishment">
            <div className="replenishment_item replenishment_item_cons">
                <h2 className="page_sub_title">Доступно</h2>
                <input type="text" value={account.balance.toFixed(currency.decimal)} readOnly/>
            </div>

            <div className="replenishment_item conclus_Sel">
                <h2 className="page_sub_title">Способ вывода</h2>
                <div className="select_drop">
                    <select value={method} onChange={event => setMethod(event.target.value)} ref={methodInputRef}>
                        {/*<option value="">Выберите способ вывода</option>*/}
                        <option value="cash">Наличные в офисе</option>
                        <option value="transfer_sber">Перевод на карту: Сбербанк</option>
                        <option value="transfer_tinkoff">Перевод на карту: Тинькофф</option>
                        <option value="transfer_all">Перевод на карту: любой банк</option>
                        <option value="atm_sber">Пополнение карты: Сбербанк</option>
                        <option value="atm_tinkoff">Пополнение карты: Тинькофф</option>
                        <option value="atm_all">Пополнение карты: любой банк</option>
                    </select>
                </div>
            </div>

            <div className="replenishment_item">
                <h2 className="page_sub_title">Номер карты</h2>
                <input
                    type="text"
                    value={pan}
                    onChange={changePan}
                    ref={panInputRef}
                    placeholder="0000 0000 0000 0000"
                />
            </div>
        </div>

        <div className="conditions">
            <h2 className="page_sub_title">Инструкции</h2>
            <p className="conditions_text">{message}</p>
        </div>

        <div className="payment_info">
            <form>
                <div className="payment_info_item payment_info_item_min_sale">
                    <h2 className="page_sub_title">Сумма выплаты</h2>
                    <input
                        type="text"
                        value={amount}
                        placeholder={`Минимум ${commissionSettings.min}`}
                        onChange={changeAmount}
                        ref={amountInputRef}
                    />
                </div>

                <div className="payment_info_item payment_info_item_commission_conc">
                    <h2 className="page_sub_title">Комиссия</h2>
                    <input type="text" value={amountCommission} readOnly/>
                </div>

                <div className="payment_info_item payment_info_item_sale payment_info_item_sale_conc">
                    <h2 className="page_sub_title">Сумма вывода</h2>
                    <input type="text" value={amountWithdraw} readOnly/>
                </div>

                <div className="payment_info_item payment_info_item_conc">
                    <button type="button" className="payment_submit" onClick={execute}>Далее</button>
                </div>
            </form>
        </div>

        <FiatWithdrawHistory history={history}/>
    </div>
}
