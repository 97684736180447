import {useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import SocialAuth from "../components/SocialAuth";
import hide from "../img/form/hide.svg";
import view from "../img/form/view.svg";
import getAuthTokenResp from "../api/auth/getAuthToken";
import paintBorder from "../utils/paintBorder";

export default function Login({user, setUser}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const emailInputRef = useRef()
    const passwordInputRef = useRef()

    function changeEmail(event) {
        setEmail(event.target.value)

        emailInputRef.current.style.borderColor = '#5f7742'
    }

    function changePassword(event) {
        setPassword(event.target.value)

        passwordInputRef.current.style.borderColor = '#5f7742'
    }

    const navigate = useNavigate()

    function showInputValue(event, inputRef) {
        const passHidden = inputRef.current.getAttribute('type') === 'password'

        inputRef.current.setAttribute('type', passHidden ? 'text' : 'password')
        event.target.setAttribute('src', passHidden ? hide : view)
    }

    async function execute() {
        if (email && password) {
            const response = await getAuthTokenResp(email, password)

            if (response.status === 201) {
                const result = await response.json()

                const user = {
                    id: result['userId'],
                    token: result['token'],
                }

                setUser(user)

                navigate('/accounts/usdt/deposit')
            } else {
                paintBorder(emailInputRef, true)
                paintBorder(passwordInputRef, true)
            }

            return
        }

        paintBorder(emailInputRef, !email)
        paintBorder(passwordInputRef, !password)
    }

    return <main className="main_content registration">
        <div className="content">
            <h1 className="page_title">Войти</h1>

            <SocialAuth/>

            <div className="text_reg_form">
                <p>Войдите с помощью электронной почты </p>
                <p>У вас ещё нет учетной записи? <NavLink to="/registration">Зарегистрироваться</NavLink></p>
            </div>

            <form className="form_reg">
                <label>
                    <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={changeEmail}
                        ref={emailInputRef}
                        placeholder="Адрес электронной почты"
                    />
                </label>
                <label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={changePassword}
                        ref={passwordInputRef}
                        placeholder="Пароль"
                    />
                    <img
                        src={view} alt="" className="hide_pass"
                        onClick={event => showInputValue(event, passwordInputRef)}
                    />
                </label>
                <label>
                    <button type="button" onClick={execute}>Вход</button>
                </label>
            </form>
        </div>
    </main>
}
