import {NavLink} from "react-router-dom";

export default function PageNotFound() {
    return <main className="main_content">
        <div className="content">
            <div className="dostup_content">
                <h1 className="dost_title">Страница не найдена</h1>
                <p className="dost_sub_title">Кажется что-то пошло не так! Страница, которую вы запрашиваете, не
                    существует. Возможно она устарела, была удалена, или был введён неверный адрес в адресной
                    строке.</p>
                <p className="link_to_home"><NavLink to="/" className="home_link">Перейти на главную</NavLink></p>
            </div>
        </div>
    </main>
}
