import Pagination from "../../Pagination"
import {Link} from "react-router-dom"

export default function CryptoDepositHistory({history, currency}) {
    const offset = 0
    const itemOnPage = 10
    const countPages = Math.ceil(history.length / itemOnPage)
    const currentPage = Math.floor(offset / itemOnPage) + 1

    return <div className="operations_history">
        <h2 className="page_title">История операций</h2>
        <table className="history_table">
            <thead>
            <tr>
                <th><Link to="?datetime">Время</Link></th>
                <th><Link to="?txid">TXID</Link></th>
                <th className="activ_sort"><Link to="?amount">Сумма</Link></th>
                <th><Link to="?confirmations">Подтверждений</Link></th>
                <th><Link to="?status">Статус</Link></th>
            </tr>
            </thead>
            <tbody>
            {history.slice(offset, offset + itemOnPage).map(({dateTime, txid, amount, confirmations, status}, i) => (
                <tr key={i}>
                    <td>{dateTime}</td>
                    <td><a href="#">{txid.substring(0, 40)}...</a></td>
                    <td>{amount.toFixed(currency.decimal)}</td>
                    <td>{confirmations}</td>
                    <td style={{width: 96}}>{status}</td>
                </tr>
            ))}
            </tbody>
        </table>

        {countPages > 1 && <Pagination all={countPages} current={currentPage}/>}
    </div>
}