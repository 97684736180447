import {Navigate} from "react-router-dom";
import {useEffect} from "react";
import logout from "../api/auth/logout";

export default function Logout({user, setUser}) {
    useEffect(() => {
        logout(user).then(() => setUser(null))
    }, [])

    return <Navigate to="/" replace />
}
