import {config} from "../Constants"

export default async function getCommissions(user, code, operation) {
    const path = `/api/v1/commissions?currency=${code}&operation=${operation}`

    const response = await fetch(config.url.API_URL + path, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + user.token,
        },
    })

    return await response.json()
}
