import logo from "../img/logo.svg"
import lang from "../img/lang.svg"
import profile from "../img/profile.svg"
import {NavLink} from "react-router-dom"

export default function Header({user}) {
    return <header>
        <div className="content">
            <div className="header_content">
                <div className="logo">
                    <a href="/"><img src={logo} alt="GITSEXCHANGE"/></a>
                </div>

                <div className="nav_content">
                    <nav>
                        <ul className="menu">
                            <li className="nav_item"><NavLink to="/accounts/usdt/deposit" className="active">Активы</NavLink></li>
                            <li className="nav_item"><NavLink to="/trading">Биржа</NavLink></li>
                        </ul>
                    </nav>

                    <div className="user_and_lang">
                        <div className="lang">
                            <div className="lang_select">
                                <span id="activ_lang">RU</span>
                                <img src={lang} className="select_lang_btn" alt="Язык"/>
                                <div className="info_show">
                                    <span onClick={() => alert('Он и так русский!')}>Русский</span>
                                    <span onClick={() => alert('Не русского нет! Только русский!')}>Не русский</span>
                                </div>
                            </div>
                        </div>

                        {!user || <div className="user">
                            <img src={profile} alt="profile"/>
                            <div className="info_show">
                                <span><NavLink to="/profile">Профиль</NavLink></span>
                                <span><NavLink to="/logout">Выйти</NavLink></span>
                            </div>
                        </div>}

                        {!user && <div className="login_content">
                            <NavLink to="/login">Вход</NavLink>
                            <NavLink to="/registration">Регистрация</NavLink>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </header>
}
