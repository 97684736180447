import {config} from '../../Constants'
import getDateFormatter from "../../utils/getDateFormatter";

export default async function exchange(user, currencyFrom, currencyTo, amount) {
    const response = await fetch(config.url.API_URL + '/api/v1/exchange-transactions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'Bearer ' + user.token,
        },
        body: JSON.stringify({
            amount: amount,
            currencyFrom: currencyFrom,
            currencyTo: currencyTo,
        }),
    })

    const transaction = await response.json()

    return {
        dateTime: getDateFormatter().format(new Date(transaction.date)),
        pair: transaction.currencyDebit + ' -> ' + transaction.currencyCredit,
        rate: transaction.rate,
        debit: (+transaction.amountDebit).toFixed(currencyFrom.decimal),
        credit: (+transaction.amountCredit).toFixed(currencyTo.decimal),
    }
}
