import {config} from '../../Constants'
import getDateFormatter from "../../utils/getDateFormatter";
import getTransactionStatuses from "../../utils/getTransactionStatuses";

export default async function withdrawFiat(user, currency, method, number, amount, prefix) {
    const response = await fetch(config.url.API_URL + '/api/v1/fiat-withdraw-transactions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': 'Bearer ' + user.token,
        },
        body: JSON.stringify({
            currency: currency.code,
            method: method,
            number: number,
            amount: amount,
        }),
    })

    const transaction = await response.json()

    return {
        dateTime: getDateFormatter().format(new Date(transaction.date)),
        number: method === 'cash' ? 'Наличные в офисе' : `${prefix} ${number}`,
        amount: (+transaction.amount).toFixed(currency.decimal),
        commission: (+transaction.commission).toFixed(currency.decimal),
        status: getTransactionStatuses()[transaction.status],
    }
}
